// AboutFooter.js
import React from 'react';
import '../css/AboutFooter.css'

const AboutFooter = () => {
  return (
    <div className="about-footer">
      <h2>About Our Shop</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Nulla ac velit vel libero volutpat aliquam eu eu orci. 
        In hac habitasse platea dictumst.
      </p>
      {/* Add more content or styling as needed */}
    </div>
  );
};

export default AboutFooter;
